import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import virtual_servers from "../../../../../assets/img/virtual_servers.jpg";
import server1 from "../../../../../assets/img/server1.gif";
import server2 from "../../../../../assets/img/server2.gif";
import server3 from "../../../../../assets/img/server3.gif";
import server4 from "../../../../../assets/img/server4.gif";

export default function ServeursVirtuelsInfogeres() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Serveurs virtuels infogérés</h1>
        <img
          src={virtual_servers}
          alt="Serveurs virtuels infogérés"
          style={{ float: "right" }}
        ></img>
        <p>
          Le serveur virtuel NEBS vous donne la possibilité de gérer votre
          propre serveur dans un environnement virtuel qui vous offre le plus
          haut degré de fiabilité et de fonctionnalité existant. Avec un serveur
          virtuel infogéré, vous consacrez votre temps à la planification et à
          l'expansion de vos affaires et à en tirer profit, et non à vous
          occuper du matériel et du logiciel serveur. NEBS prend soin de tout :
          matériel, logiciels et infrastructure du système.
        </p>
        <h2>Les serveurs virtuels infogérés sont parfaits pour :</h2>
        <ul>
          <li>Les développeurs Web</li>
          <li>Les revendeurs de services d’hébergement Web</li>
          <li>
            Les concepteurs de sites Web et les ateliers de conception graphique
          </li>
          <li>Toute entreprise qui doit héberger de 10 à 100 sites</li>
          <li>Les associations</li>
        </ul>
      </div>
      <div className={styles.row}>
        <h2>Forfaits serveur virtuel infogéré</h2>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>10 domaines</h3>
            <img src={server1} alt="Serveur en bronze"></img>
            <h4>100,00 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <ul>
              <li>Installation GRATUITE</li>
              <li>3 Go de stockage sur disque RAID</li>
              <li>Limite de transfert de 540 Go par mois</li>
              <li>16 Go d'espace courriel RAID</li>
              <li>157 boîtes aux lettres (250 Mo chacune)</li>
            </ul>
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>20 domaines</h3>
            <img src={server2} alt="Serveur d'argent"></img>
            <h4>160,00 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <ul>
              <li>Installation GRATUITE</li>
              <li>6 Go de stockage sur disque RAID</li>
              <li>Limite de transfert de 1 080 Go par mois</li>
              <li>31 Go d'espace courriel RAID</li>
              <li>313 boîtes aux lettres (250 Mo chacune)</li>
            </ul>
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>50 domaines</h3>
            <img src={server3} alt="Serveur d'or"></img>
            <h4>325,00 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <ul>
              <li>Installation GRATUITE</li>
              <li>15 Go de stockage sur disque RAID</li>
              <li>Limite de transfert de 2 700 Go par mois</li>
              <li>78 Go d'espace courriel RAID</li>
              <li>783 boîtes aux lettres (250 Mo chacune)</li>
            </ul>
          </div>
        </div>
        <div className={styles.column_4}>
          <div className={styles.card}>
            <h3>100 domaines</h3>
            <img src={server4} alt="Serveur platine"></img>
            <h4>625,00 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <ul>
              <li>Installation GRATUITE</li>
              <li>30 Go de stockage sur disque RAID</li>
              <li>Limite de transfert de 5 400 Go par mois</li>
              <li>157 Go d'espace courriel RAID</li>
              <li>1 567 boîtes aux lettres (250 Mo chacune)</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <h2>Caractéristiques...</h2>
        <div className={styles.column_3}>
          <div className={styles.card}>
            <h3>Facturation sur mesure</h3>
            <ul>
              <li>Passerelles de paiement configurables</li>
              <li>Ajout de vos passerelles de prédilection</li>
              <li>Ajout de produits</li>
              <li>Choix des frais facturés</li>
              <li>Dates de facturation configurables</li>
              <li>Options de paiement</li>
              <li>Possibilité d'ajout de frais ou de paiement uniques</li>
              <li>Facture personnalisée</li>
            </ul>
          </div>
        </div>
        <div className={styles.column_3}>
          <div className={styles.card}>
            <h3>Outils de gestion</h3>
            <ul>
              <li>Importation de domaines</li>
              <li>Page « en construction » personnalisée</li>
              <li>Plus de 3 400 modèles de sites Web personnalisables</li>
              <li>Boîte à outils de l'entreprise d'hébergement Web</li>
              <li>Panneau de configuration personnalisable</li>
              <li>Ajout de vos propres informations</li>
              <li>Couleurs et polices modifiables</li>
            </ul>
          </div>
        </div>
        <div className={styles.column_3}>
          <div className={styles.card}>
            <h3>Gestion de domaine</h3>
            <ul>
              <li>Modification des adresses courriel</li>
              <li>Mise à jour des renseignements sur les clients</li>
              <li>Redirection de domaine</li>
              <li>Gestion des mots de passe de domaine</li>
              <li>
                Changement de type de forfait
                <ul>
                  <li>garé</li>
                  <li>redirigé</li>
                  <li>hébergé</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
