import React from "react";
import Layout from "../../../../other/layout";
import ServeursVirtuelsInfogeres from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/ServeursVirtuelsInfogeres/ServeursVirtuelsInfogeres";
import SEO from "../../../../other/seo";

export default function serveursVirtuelsInfogeres() {
  return (
    <Layout>
      <SEO title="Serveurs virtuels infogérés" />
      <ServeursVirtuelsInfogeres />
    </Layout>
  );
}
